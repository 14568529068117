import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/reset.css'
import './style/index.less'
import 'amfe-flexible'
import axios from 'axios'
import './network/mock/mock.js' // 引入mock.js文件
// test
import { Swipe, SwipeItem, Toast, Lazyload } from 'vant'

// eslint-disable-next-line no-unused-vars
import Vconsole from 'vconsole'
// eslint-disable-next-line no-new
new Vconsole()

Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Toast)
// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true
  // loading: require('../src/assets/loading.gif') // 注意路径使用require
})

Vue.config.productionTip = false

// 设置默认请求头
axios.defaults.baseURL = 'http://ggena/'
// 全局主持axios 使用this.$axios
Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
