import data1 from './scinea.json' // 想要返回的数据
import data2 from './music.json' // 想要返回的数据
var Mock = require('mockjs') // 获取mock对象

Mock.mock('http://ggena/scinea', 'post', // “拦截域名”，“请求方式”，“返回数据”
  req => {
    console.log(req) // 请求体，用于获取参数
    return data1
  }
)

Mock.mock('http://ggena/music', 'post', // “拦截域名”，“请求方式”，“返回数据”
  req => {
    console.log(req) // 请求体，用于获取参数
    return data2
  }
)
